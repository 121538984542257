.report-page {
  height: 100%;

  &__container {
    width: 100%;
    height: 100%;
    padding: 18px 24px;
  }

  .page {
    &__title {
      margin-bottom: 9px;
    }

    &__toolbar {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 16px;
      height: 30px;
    }
  }

  .report {
    &__wrapper {
      flex: 1;
      min-height: 0;
      border-radius: 6px;
      border: 1px solid white;
      overflow: hidden;

      &__inner {
        height: 100%;
        display: flex;
        flex-direction: row;
        overflow: auto;

        //@media (max-width: 960px) {
        //  flex-direction: column;
        //}

        &::-webkit-scrollbar {
          width: 8px;
          background-color: #2e3236;
        }
        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background-color: #2e3236;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #497a81;
        }
        &::-webkit-scrollbar-corner {
          background-color: #2e3236;
        }
      }
    }

    &__no-data {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      opacity: 0.5;
    }

    &__options {
      height: 100%;
      width: 300px;
      padding: 10px;
      border-right: 1px white dashed;

      &__title {
        text-align: center;
        text-decoration: underline;
        margin-bottom: 14px;
      }

      &__item {
        white-space: nowrap;
        margin-bottom: 10px;

        span {
          opacity: 0.3;
        }

        p {
          margin-top: 5px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__main {
      height: 100%;
      width: 100%;
      padding: 10px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #2e3236;
      }
      &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: #2e3236;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #497a81;
      }
      &::-webkit-scrollbar-corner {
        background-color: #2e3236;
      }

      &__title {
        text-align: center;
        text-decoration: underline;
        margin-bottom: 14px;
      }

      &__item {
        white-space: nowrap;
        margin-bottom: 10px;

        span {
          opacity: 0.3;
        }
      }
    }
  }

  @media (max-width: 960px) {
    .report__wrapper__inner {
      flex-direction: column;
    }

    .report__options {
      height: auto;
      width: auto;
      border-right: none;
      border-bottom: 1px dashed white;
    }
  }
}

.analytical-report-page {
  height: 100%;
  display: flex;
  flex-direction: row;

  .filter-item {
    margin-right: 0;

    &__title {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      margin-left: auto;
    }
  }

  .report {
    &__side-panel {
      width: 442px;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #2e3236;
    }

    &__info {
      padding: 24px 20px;

      &__item {
        white-space: nowrap;
        margin-bottom: 20px;

        h3 {
          font-size: 12px;
          opacity: 0.3;
          margin-bottom: 8px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    &__elements-wrapper {
      display: flex;
      flex-wrap: wrap;
      row-gap: 16px;
      column-gap: 16px;
    }

    &__element {
      flex-basis: calc(50% - 8px);
      //max-width: 193px;
      padding: 16px;
      border-radius: 6px;
      border: 1px solid rgba(255, 255, 255, 0.2);

      &__title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 12px;
      }

      .info-block {
        width: 100%;

        &_inout {
          @extend .info-block;
          display: flex;

          .info-block__item:last-child {
            padding-left: 16px;
            border-left: 1px solid rgba(255, 255, 255, 0.2);
          }
          .info-block__item {
            width: 50%;
          }
        }

        &__item {
          h3 {
            opacity: 0.4;
            margin-bottom: 8px;
            font-size: 12px;
          }

          span {
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }

    &__toolbar {
      display: flex;
      flex-direction: column;
      padding: 24px 20px;

      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    &__filters {
      margin-bottom: 13px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      font-weight: 600;

      &__datetime-range {
        position: relative;
        height: 28px;

        &_divider {
          position: absolute;
          top: 6px;
          left: 206px;
        }

        .rdt:first-child {
          margin-right: 23px;
        }

        .rdt:last-child {
          margin-right: 0;
        }

        .rdt {
          &::before {
            display: none;
          }

          .form-control {
            padding: 0 7px;
          }
        }
      }
    }

    &__wrapper {
      padding: 20px 0;
      width: 100%;
      height: 100%;
      display: flex;

      &__inner {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: auto;
        min-height: 5%;
        max-height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 8px;
          background-color: #2e3236;
        }
        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background-color: #2e3236;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #497a81;
        }
        &::-webkit-scrollbar-corner {
          background-color: #2e3236;
        }
      }
    }
  }
}