.workflow-form {
  &__tabs {
    .nav-item {
      width: 25%;
    }
  }

  &__camera-tabs {
    .nav-item {
      width: 50%;
    }
  }

  &__tab-content {
    min-height: 330px;
    max-height: calc(90vh - 300px);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #2e3236;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #2e3236;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #497a81;
    }

    fieldset {
      background-color: transparent !important;

      legend {
        margin-bottom: 10px;
      }
    }

    .field {
      &-description {
        text-transform: capitalize;
        margin-bottom: 10px;
      }
    }

    .control-label {
      margin-bottom: 5px;
    }

    .btn {
      background-color: #497a81;
      color: white;

      &_reset {
        color: #497a81;
        cursor: pointer;
      }
    }

    .camera-preview {
      @extend .w-100;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 192px;

      @media (max-width: $sm) {
        max-width: 100%;
        height: auto;
      }

      @media (max-width: 380px) {
        min-height: 133px;
      }

      .connect,
      .error {
        position: absolute;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
          width: 36px;
          height: 26px;
          margin-bottom: 10px;
        }

        svg,
        span {
          color: rgba($black, 0.3);
        }
      }

      .connect {
        .loader-wrap {
          background: transparent;
          transform: scale(0.65);
          top: -17px;
          left: -2px;
        }

        span {
          display: block;
          padding-top: 50px;
          color: $white;
        }
      }

      .error {
        svg,
        span {
          color: $error;
        }
      }
    }
  }
}
.presets--list {
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 28px;
  background-color: #212529;
  border-radius: 6px;
  overflow-y: scroll;
  max-height: 300px;
  min-height: 50px;
  .form-check {
    padding-bottom: 1rem;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #497a81;
  }
  &::-webkit-scrollbar-corner {
    background-color: #2e3236;
  }
}
