.form__creation {
  .camera-preview {
    @extend .w-100;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 192px;

    @media (max-width: $sm) {
      max-width: 100%;
      height: auto;
    }

    @media (max-width: 380px) {
      min-height: 133px;
    }

    .connect,
    .error {
      position: absolute;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        width: 36px;
        height: 26px;
        margin-bottom: 10px;
      }

      svg,
      span {
        color: rgba($black, 0.3);
      }
    }

    .connect {
      .loader-wrap {
        background: transparent;
        transform: scale(0.65);
        top: -17px;
        left: -2px;
      }

      span {
        display: block;
        padding-top: 50px;
        color: $white;
      }
    }

    .error {
      svg,
      span {
        color: $error;
      }
    }
  }
}