.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: $black;
  opacity: 0.6;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  font-family: 'Nunito Sans', sans-serif;

  &-dialog {
    max-width: 600px;
    margin: 60px auto 40px;
    position: relative;
    width: auto;
    pointer-events: none;
    //animation-name: fadeInDown;
    //animation-duration: 0.2s;
    //animation-fill-mode: both;

    @media (max-width: $sm) {
      margin: 40px 20px;
    }

    @media (max-width: $xxs) {
      margin: 20px;
    }
  }

  &-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: $black-2;
    background-clip: padding-box;
    border: 1px solid $black-3;
    outline: 0;
  }

  &-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 30px;
    border: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .close {
      @extend .btn--transparent;
      padding: 0;
      margin: 0;
      width: 20px;
      height: 20px;
      position: relative;
      color: #afafaf;
      opacity: 1;
      text-shadow: none;

      &:focus {
        outline: none;
      }

      &:hover {
        color: $grey-2;
      }

      span {
        position: absolute;
        top: -4px;
        left: 0;
        width: 20px;
        font-size: 28px;
        line-height: 1;
        font-weight: bold;
      }
    }
  }

  &-title {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    line-height: 33px;
  }

  &-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0 30px 10px;
    .react-datepicker-wrapper {
      width: 100%;
      .react-datepicker__input-container {
        width: 100%;
      }
    }
  }

  &-footer {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 30px;
    border: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .btn {
      min-width: 100px;
      min-height: 46px;
      margin-left: 10px;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 16px;
      font-weight: 800;

      &.close {
        background: $grey-2;
        transition: opacity 0.3s, background-color 0.3s;

        &:hover {
          background-color: rgba($black-3, 0.5);
        }
      }
    }
  }

  &-users {
    .form-fields {
      display: flex;

      @media (max-width: $sm) {
        flex-wrap: wrap;
      }

      & > div {
        position: relative;

        &:first-child {
          margin-right: 20px;

          @media (max-width: $sm) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &_small {
    max-width: 300px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.75;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
