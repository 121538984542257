.react-datepicker {
  font-family: 'Nunito Sans', sans-serif;
  background-color: $grey-3;
  border: none;
  box-shadow: 0 2px 10px 0 rgba($black, 0.3);

  &__day {
    color: $white;

    &:hover {
      background-color: $black-3;
    }

    &--keyboard-selected,
    &--selected {
      background: $metallic-blue;
      &:hover {
        background: $metallic-blue;
      }
    }
  }

  &__header {
    background-color: $grey-3;
    border-bottom-color: $black;
  }

  &__current-month {
    margin-bottom: 5px;
    color: $white;
  }

  &__day-name {
    color: $white;
  }

  &__navigation {
    &--next {
      border-left-color: rgba($white, 0.4);
      &:hover {
        border-left-color: rgba($white, 1);
      }
    }
    &--previous {
      border-right-color: rgba($white, 0.4);
      &:hover {
        border-right-color: rgba($white, 1);
      }
    }
  }
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-bottom-color: $grey-3;
}

.react-datepicker-popper {
  z-index: 100;
}
