$black: #000;
$black-1: #181a1d;
$black-2: #2e3236;
$black-3: #0c1516;
$black-4: #212529;
$black-opacity-2: rgba(0, 0, 0, 0.2);
$black-opacity-3: rgba(0, 0, 0, 0.3);
$white: #fff;
$red: #ff001f;
$red-2: #d0021b;
$red-3: #ff1313;
$red-4: #c1201e;
$red-5: #c73030;

$grey: #849495;
$grey-4: #e0ddd8;
$grey-2: #3e444a;
$grey-3: #3c4248;
$grey-4: #585b5e;
$metallic-blue: #497a81;
$metallic-blue-opacity: rgba(73, 122, 129, 0.3);
$brown: #ae7e02;

//text
$no-selected: #202326;
$active: #56bf80;
$error: $red;

$lg: 1200px;
$md: 1044px;
$sm: 767px;
$xs: 575px;
$xxs: 320px;

$shadows: 0px 2px 10px rgba(0, 0, 0, 0.3),;
