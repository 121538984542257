.file-upload {
  button {
    font-size: 12px;
    color: $metallic-blue;
    font-weight: normal;
    margin-left: 10px;
    padding: 0;
    opacity: 1;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
  }
}

.upload-gallery {
  &-list {
    display: flex;
    flex-direction: row;
    margin: 0 -10px;
    overflow-x: auto;
    overflow-y: hidden;
    list-style: none;
    padding-left: 0;

    @include mq($sm) {
      flex-wrap: wrap;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &-item {
    position: relative;
    flex-basis: calc(33.333% - 20px);
    box-sizing: border-box;
    margin: 10px;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    @include mq($sm, max) {
      flex: 0 0 120px;
    }

    @include mq($lg) {
      flex-basis: calc(25% - 20px);
    }
  }

  &-content {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    @include position(absolute, 0 0 0 0);
    @include size(100%);
    overflow: hidden;
    border-radius: 6px;
    color: #fff;
    &:before {
      content: '';
      @include size(100%, 40px);
      @include position(absolute, 0 null null 0);
      background-image: linear-gradient(to top, rgba($black, 0), $black);
      opacity: 0.8;
      z-index: 10;
    }

    &.--upload {
      margin-bottom: 0;
      cursor: pointer;
      font-size: 16px;
      &:before {
        display: none;
      }
    }

    &.--warning {
      text-align: center;
      user-select: none;
      color: $error;
      figure {
        margin: 15px 15px 30px;
        figcaption {
          margin: 5px 0 -25px;
          line-height: 11px;
          overflow: hidden;
          font-size: 10px;
        }
      }

      .fa-exclamation-triangle {
        font-size: 16px;
      }
    }
  }

  &-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    @include position(absolute, 0 0 null null);
    @include size(20px);
    color: $white;
    opacity: 0.3;
    z-index: 15;
    &.disabled {
      pointer-events: none;
      cursor: default;
    }

    &:hover {
      opacity: 1;
    }

    @include mq($sm) {
      @include size(30px);
    }
  }
}
