.alerts__table-avatar-wrapper {
  padding-right: 8px;
}

.alerts__table-avatar-count {
  height: 24px;
  background-color: $black-opacity-3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 0 4px;
}
