.player-collapse {
  width: 100%;
  padding: 19px 20px;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  border-radius: 8px;

  &:nth-of-type(n + 2) {
    margin-top: 8px;
  }
}

.player-collapse__title {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  p {
    font-weight: 800;
    color: $white;
    opacity: 0.3;
    font-size: 14px;
  }
}

.player-collapse__title-icon {
  transform: rotate(180deg);
  transition: transform 0.3s;

  &--open {
    transform: none;
  }
}

.player-collapse__collapse {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s linear;

  &--open {
    max-height: 300px;
    overflow: visible;
  }
}

.player-collapse__player-options {
  //
}

.player-collapse__player-options__item {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 12px;
  overflow: visible;

  &--analytics {
    flex-direction: column;
    .input-player-container {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      .input-player-date {
        width: 40px;
        height: 20px;
        font-size: 11px;
        text-align: center;
        margin: 0 2%;
        color: white;
        border: 1px solid rgb(141, 140, 140);
        border-radius: 2px;
      }
      .input-player-span {
        padding-top: 2px;
        padding-right: 2px;
      }
    }
  }

  p {
    &:nth-of-type(n + 2) {
      margin-top: 8px;
    }
  }
}

.player-big-widget {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  overflow: visible;

  &--analytics {
    flex-direction: column;
    > p {
      margin-top: 10px;
      font-size: 20px;
    }
    .input-player-container {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;

      .input-player-date {
        width: 50px;
        height: 30px;
        font-size: 20px;
        text-align: center;
        margin: 0 2%;
        color: white;
        border: 1px solid rgb(141, 140, 140);
        border-radius: 2px;
      }
      .input-player-span {
        padding-top: 2px;
        padding-right: 2px;
        font-size: 20px;
      }
    }
  }
  p {
    &:nth-of-type(n + 2) {
      margin-top: 8px;
    }
  }
}

.dark-theme-datepicker {
  background-color: rgba(255, 255, 255, 0.76);
  height: fit-content;
  width: fit-content;
  color: black;
  border-radius: 4px;
  margin-left: 2px;
  overflow: visible;
}
