.notifications {
  position: fixed;
  right: 0;
  z-index: 9999;

  &-element-wrap {
    margin: 10px 25px;
    border-radius: 4px;
    border: solid 0.5px rgba(255, 255, 255, 0.5);
    background-color: rgba(73, 122, 129, 0.95);
    display: flex;
    justify-content: space-between;

    a {
      width: 100%;
      text-decoration: none;
      color: inherit;
    }

    a:hover {
      opacity: inherit;
    }

    img {
      margin-left: 10px;
      margin-bottom: 1px;
      cursor: pointer;
      opacity: 0.5;
      margin-right: 12px;
    }
  }

  &-element {
    padding: 15px 0 15px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    div {
      &:not(:first-of-type) {
        white-space: nowrap;
      }
    }

    &-left {
      padding-right: 20px;
      text-align: left;
    }

    &-right {
      text-align: right;
      opacity: 0.5;
    }

    &-img-wrap {
      display: inline-block;
      vertical-align: middle;
      padding: 15px 0;
    }

    b {
      cursor: pointer;
    }
  }
}
