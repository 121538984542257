$theme-colors: (
  'primary': $metallic-blue,
  'text-primary': $white,
  // 'info': tomato,
  // 'danger': teal,
  // 'blue': red,
  'text-no-selected': $no-selected,
  'text-active': $active,
  'text-partly': $brown,
  'text-error': $red,
);
// grid spacing
$grid-gutter-width: 10px;

/* import bootstrap to set changes */
// @import '~bootstrap/scss/bootstrap';

// required
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// parts
@import '~bootstrap/scss/root';
// @import "~bootstrap/scss/reboot";
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
// @import "~bootstrap/scss/buttons";
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

// bootstrap ovverrides

// card {
.card {
  background-color: $black-2;
  border-radius: 6px;
  padding: 20px;
}
// switch
.custom-switch {
  .custom-control-input {
    & ~ .custom-control-label {
      user-select: none;

      &::before {
        width: 36px;
        height: 20px;
        border-radius: 10px;
        left: -38px;
        top: 0;
        border: none;
        background-color: $no-selected;
      }

      &:after {
        left: -35px;
        width: 16px;
        height: 16px;
        top: 2px;
        background-color: $white;
      }
    }

    &:checked {
      & ~ .custom-control-label {
        &::after {
          transform: translateX(14px);
        }

        &:before {
          color: #fff;
          border-color: $metallic-blue;
          background-color: $metallic-blue;
        }
      }
    }

    &:checked,
    &:focus {
      & ~ .custom-control-label::before {
        box-shadow: none;
      }
    }
  }

  &--small {
    .custom-control-input {
      & ~ .custom-control-label {
        user-select: none;

        &::before {
          width: 24px;
          height: 14px;
          border-radius: 7px;
          left: -28px;
          top: 4px;
          border: none;
        }

        &:after {
          left: -26px;
          width: 10px;
          height: 10px;
          top: 6px;
        }
      }

      &:checked {
        & ~ .custom-control-label {
          &::after {
            transform: translateX(10px);
          }

          &:before {
            color: #fff;
            border-color: $metallic-blue;
            background-color: $metallic-blue;
          }
        }
      }

      &:checked,
      &:focus {
        & ~ .custom-control-label::before {
          box-shadow: none;
        }
      }
    }
  }
}

// dropdown
.dropdown {
  .btn {
    color: $white;
    // line-height: 1;

    &.dropdown__button--no-padding {
      padding: 0 4px;
      border: none;
    }

    .dropdown-dots-menu__icon {
      vertical-align: unset;
      height: auto;
      width: 16px;
    }
  }
}
.dropdown-divider {
  width: calc(100% - 20px);
  margin: 4px auto;
  border-top: 1px solid $black-opacity-2;
}

.dropdown-menu {
  max-height: 90%;
  min-width: 144px;
  border-radius: 6px;
  background-color: $grey-3;
  padding: 10px 0;
  box-shadow: nth($shadows, 1);
  border: none;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #497a81;
  }
}

.dropdown-item {
  padding: 4px 20px;
  font-size: 14px;
  line-height: 1.36;
  text-align: left;
  transition: background-color 0.3s;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: $black-2;
    color: $white;
  }

  .dropdown-dots-menu-item__option-item__icon {
    width: 16px;

    &:first-child {
      margin-right: 10px;
    }
  }
}

//checkbox
.custom-checkbox {
  & .custom-control-label {
    &::before {
      border: 1px solid #BCBCBC;
      border-radius: 2px;
      background-color: transparent;
    }

    &:after {
      background-size: 11px;
    }
  }
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.nav-tabs {
  &.nav {
    border-bottom: none;
    background-color: $black-opacity-3;
    border-radius: 6px;
    margin-top: 24px;
    margin-bottom: 20px;
    & .nav-item {
      display: flex;
      justify-content: center;
      & .nav-link {
        width: 100%;
        @extend .btn;
        color: $white!important;
        padding-top: 7px;
        padding-bottom: 6px;

        margin-bottom: 0;
        cursor: pointer;
        border: none;

        &.active {
          pointer-events: none;
          @extend .btn--secondary;
        }
      }
    }
  }
}

// react-jsonschema-form
.rjsf {
  .field-array {
    .btn {
      &-group {
        margin-bottom: 10px;
      }

      &-add {
        padding: 1px 6px;
        i::before {
          display: inline-block;
          content: "";
          vertical-align: -.125em;
          background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' /></svg>");
          background-size: cover;
          width: 20px;
          height: 20px;
        }
      }
    }

    .array-item {
      &-move-up {
        padding: 0;
        i::before {
          display: inline-block;
          content: "";
          vertical-align: -.125em;
          background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z' /></svg>");
          background-size: cover;
          width: 20px;
          height: 20px;
        }
      }
      &-move-down {
        padding: 0;
        i::before {
          display: inline-block;
          content: "";
          vertical-align: -.125em;
          background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path ill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z' /></svg>");
          background-size: cover;
          width: 20px;
          height: 20px;
        }
      }
      &-remove {
        padding: 0;
        i::before {
          display: inline-block;
          content: "";
          vertical-align: -.125em;
          background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' /></svg>");
          background-size: cover;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

//JSON editor
.jsoneditor {
  border: 1px solid $metallic-blue-opacity !important;

  &-tree {
    &-inner {
      background-color: $black-2;
    }
  }

  &-menu {
    background-color: $metallic-blue-opacity !important;
    border-bottom: 1px solid $metallic-blue-opacity !important;
  }

  &-frame {
    background-color: $grey-4 !important;
  }

  &-navigation-bar {
    background-color: $grey-3 !important;
    border-bottom: 1px solid $black-1 !important;
  }

  &-dragarea {
    display: none;
  }

  &-highlight {
    background-color: $grey-2 !important;
  }

  &-field {
    color: $white !important;

    &[contenteditable=true]:hover,
    &[contenteditable=true]:focus {
      background-color: $grey-4 !important;
     border: 1px solid $grey-4 !important;
    }
  }

  &-value {
    &[contenteditable=true]:hover,
    &[contenteditable=true]:focus {
      background-color: $grey-4 !important;
     border: 1px solid $grey-4 !important;
    }
  }

  &-string {
    color: #56bf80 !important;
  }

  &-number {
    color: $red !important;
  }

  &-boolean {
    color: $brown !important;
  }

  &-object {
    color: $grey !important;
  }
}

// react-notifications
.notification {
  &-container {
    &::-webkit-scrollbar {
      width: 4px;
      background-color: #2e3236;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #2e3236;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #497a81;
    }
  }

  &-info {
    background-color: $metallic-blue;
  }
}

// react-datetime-range-picker
.datetime-range-filter {
  display: flex;
  height: 100%;
  border-radius: 6px;

  .rdt {
    margin-right: 5px;

    &:first-child {
      width: 225px;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        content: 'From:';
        display: flex;
        align-items: center;
        padding-left: 7px;
      }
    }

    &:last-child {
      width: 205px;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        content: 'To:';
        display: flex;
        align-items: center;
        padding-left: 7px;
      }

      .form-control {
        padding-left: 30px;
      }
    }

    .form-control {
      height: 100%;
      padding: 0 30px 0 50px;
      background-color: rgba(0, 0, 0, 0.3) !important;
      font-size: 14px;
      border: none  !important;
    }

    .rdtPicker {
      margin-top: 5px;
      background-color: #212529;
      border: none;
      box-shadow: 0 2px 10px 0 rgba($black, 0.3);
      border-radius: 6px;

      th, td {
        padding: 5px 0;
        vertical-align: middle;
      }

      .rdtPrev, .rdtNext {
        padding: 0 5px;
      }

      .rdtPrev, .rdtSwitch, .rdtNext,
      .rdtDay, .rdtTimeToggle,
      .rdtMonth, .rdtYear, .rdtBtn {
        border-radius: 6px;

        &:not(.rdtActive):hover {
          background-color: rgba(#497a81, 0.5);
        }
      }

      .rdtActive {
        background-color: #497a81;
      }
    }

    .rdtDays {
      .in-selecting-range {
        background-color: rgba(#497a81, 0.4);
      }
    }
  }
}

.datetime-range-filter__wrapper {
  position: relative;

  .datetime-range-icon {
    position: absolute;
    top: 0;
    height: 100%;
    cursor: pointer;

    &_from {
      @extend .datetime-range-icon;
      left: 200px;

      &.holey {
        pointer-events: none;
      }
    }

    &_to {
      @extend .datetime-range-icon;
      left: 410px;

      &.holey {
        pointer-events: none;
      }
    }
  }
}

