.form {
  &-control {
    display: block;
    @include size(100%, 46px);
    background-clip: padding-box;
    border: none;
    background-color: rgba($black, 0.3);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 6px 12px;
    font-weight: 400;
    font-family: 'Nunito Sans', sans-serif;
    line-height: 1.5;
    border-radius: 6px;
    font-size: 16px;
    outline: none;
    color: $white;

    &:disabled {
      background-color: rgba($black, 0.3);
    }

    &:focus {
      transform: none;
      background-color: rgba($black, 0.3);
      box-shadow: none;
      border: none;
      color: #fff;
      outline: none;
    }

    &::placeholder {
      color: $white;
      opacity: 0.4;
    }

    &--small {
      font-size: 14px;
      height: 30px;
    }
  }

  &-fields {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    row-gap: 14px;
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    width: 100%;
    margin: 0;

    &_mb-28 {
      @extend .form-row;
      margin-bottom: 28px;
    }

    &_mb-10 {
      @extend .form-row;
      margin-bottom: 10px;
    }

    &_mt-10 {
      @extend .form-row;
      margin-top: 10px;
    }
  }

  &-group-field {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 18px 5px 12px;

    &-label {
      font-size: 12px;
      line-height: 19px;
    }
  }

  &-group-title {
    text-align: center;
    margin-bottom: 14px;
    font-size: 12px;
    font-weight: 800;
    opacity: 0.5;
  }

  &-item {
    position: relative;
    min-height: 46px;
    font-family: 'Nunito Sans', sans-serif;

    &--one {
      width: 100%;
    }
    &--two {
      width: calc(50% - 5px);
    }
    &--three {
      width: calc(33% - 10px);
    }
    &--four {
      width: calc(25% - 10px);
    }

    &--text {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--responsive {
      &-label {
        position: absolute;
        top: 14px;
        left: 12px;
        font-size: 16px;
        line-height: 19px;
        transition: 0.3s;
        z-index: 1;
        cursor: text;
        &--active {
          @extend .form-item--responsive-label;
          top: 4px;
          font-size: 12px;
        }
      }

      & input:focus + label {
        @extend .form-item--responsive-label;
        top: 4px;
        font-size: 12px;
      }

      & input,
      & input:focus {
        padding-top: 22px;
      }
    }
    .react-numeric-input b {
      width: auto !important;
      right: 15px !important;
      border: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }

    .css-2b097c-container {
      height: 100%;
    }

    &__input-eye {
      position: absolute;
      width: 14px;
      height: auto;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 3;

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &--crossed {
        @extend .form-item__input-eye;
        background: linear-gradient(
          45deg,
          rgba(0, 0, 0, 0) calc(50% - 2px),
          rgba(#e0e0e0, 0.5) calc(50%),
          rgba(0, 0, 0, 0) calc(50% + 2px)
        );
      }
    }
  }

  &-input {
    &--active {
      padding-top: 22px;
    }
  }

  &-datepicker {
    &-icon {
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      cursor: default;
    }
  }

  &-password-group {
    margin: 0 auto;
    width: 266px;
    display: flex;
    flex-direction: column;
  }

  &-big-toggle {
    width: 100%;
    height: 32px;
    display: flex;
    border-radius: 6px;
    overflow: hidden;

    &-item {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $black-opacity-3;
      font-size: 14px;
      font-weight: 700;
      cursor: default;

      &--active {
        @extend .form-big-toggle-item;
        background-color: $metallic-blue;
      }
    }
  }

  &-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
      color: white;
    }
  }

  &-switch {
    &-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .custom-switch {
        padding-left: 30px;
      }
    }

    &-label {
      margin-left: 8px;
    }
  }

  &-option-icon {
    margin-right: 8px;
  }

  &__creation {
    padding: 10px;
    border: 1px solid #497a81;
    border-radius: 6px;
    margin-bottom: 10px;

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;

      &-btn {
        opacity: 0.5;

        &:hover {
          opacity: 0.7;
        }
      }

      &-times {
        width: 16px;
        color: white;
        font-size: 16px;
      }
    }

    &-fields {
      margin-top: 10px;
    }

    &__add-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      padding: 7px 15px;
      font-size: 16px;
      color: white;
      background-color: #497a81;
      border-radius: 6px;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

input[type='file'].form-control {
  height: 45px;
}

.form-radio-group {
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;
}

label,
.label-control {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 12px;
  color: rgba($white, 0.5);
}

.form-group {
  margin-bottom: 16px;
  &-icon {
    position: relative;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      @include size(36px, 46px);
      @include position(absolute, 0 null null null);
      padding-right: 6px;
      padding-left: 6px;
      color: $grey;
      svg {
        @include size(16px);
        object-fit: contain;
      }

      &:hover {
        color: $white;
      }
    }

    &-left {
      .form-control {
        padding-left: 36px;
      }

      .btn {
        left: 0;
      }
    }

    &-right {
      .form-control {
        padding-right: 36px;
      }

      .btn {
        right: 0;
      }
    }
  }

  &-compress {
    position: relative;
    padding-bottom: 18px;
    margin-bottom: 0;
    .invalid-feedback {
      @include position(absolute, null null 3px null);
    }
  }

  &-label {
    position: absolute;
    top: 14px;
    left: 12px;
    font-size: 16px;
    line-height: 19px;
    transition: 0.3s;
    &--active {
      @extend .form-group-label;
      top: 4px;
      font-size: 12px;
    }
  }

  & input:focus + label {
    @extend .form-group-label;
    top: 4px;
    font-size: 12px;
  }

  & input,
  & input:focus {
    padding-top: 22px;
  }
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 11px;
  color: $error;
  &:first-letter {
    text-transform: capitalize;
  }
}

.form-footer {
  &--new-camera {
    margin-top: 30px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      top: -24px;
      left: 0px;
      border-top: 1px solid $black-opacity-3;
    }
  }
}
.file--custom--input {
  border: 1px solid #497a81;
  border-radius: 10px;
  background-color: #497a81;
  color: #fff;
  display: inline-block;
  padding: 6px 12px;
  margin: auto;
  margin-bottom: 5px;
  cursor: pointer;
  input[type='file'] {
    display: none;
  }
  ~ .file--icon {
    width: 150px;
    height: 150px;
    margin: auto;
    padding: 10px;
    border: 5px solid white;
    border-radius: 10px;
  }
  ~ img {
    width: 150px;
    height: 150px;
    margin: auto;
    background-color: transparent;
    border: none;
    border-radius: 10px;
  }
}

.form--checkbox--group {
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 28px;
  flex-wrap: wrap;
  .form--checkbox--item {
    width: 50%;
    padding: 5px 15px;
  }
}
