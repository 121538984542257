.user {
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  padding-top: 60px;
}

.user__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.user__data {
  padding: 30px 20px 40px;
  background-color: $black-2;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.user__btn-edit {
  position: absolute;
  right: 20px;
  top: 25px;

  .user__btn-edit-icon {
    width: 20px;
    height: 20px;
  }
}

.user__buttons {
  margin-top: 10px;

  .btn--color-error {
    margin-top: 28px;
  }
}

.user__name {
  font-size: 20px;
  line-height: 1.35;
  margin-top: 14px;
  font-weight: 800;
}

.user__email,
.user__phone {
  font-size: 14px;
  line-height: 1.35;
  margin-top: 4px;
}
.user__profile__icon {
  width: 110px !important;
  height: 110px;
  padding: 20px;
  border-radius: 50%;
  border: 1px solid white;
}
.not--found--page {
  display: flex;
  align-items: center;
  justify-content: center;
  .not--found--page__item {
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 20px;
    border-radius: 50px;
    padding: 40px;
  }
}
