.select {
  cursor: pointer;
  &__indicator-separator {
    display: none;
  }
  &__menu {
    margin: 0;
    top: 100%;
    box-shadow: none;
    font-size: 14px;
    z-index: 10;
    color: $black;

    &-list {
      padding: 0;

      &--is-multi {
        height: auto !important;
      }
    }
  }
}
