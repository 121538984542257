.camera-list-base {
  @extend .list-unstyle;
  @extend .list-unstyle;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 4fr));
  -webkit-overflow-scrolling: touch;

  li {
    a {
      display: block;

      &:hover {
        opacity: 1;
        text-decoration: none;
      }
    }

    &.not-found-list {
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      max-width: 100%;
    }
  }

  &-item {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #939597;
    text-align: center;
    overflow: hidden;
    font-size: 13px;
    color: $white;
    height: 185px;
    margin: 0;
    &:before {
      content: '';
      @include size(100%, 50px);
      @include position(absolute, -10px null null 0);
      background-image: linear-gradient(to top, rgba($black, 0), $black);
      opacity: 0.8;
      z-index: 10;
    }

    &-bottom-text {
      display: flex;
      align-items: flex-end;

      &:after {
        content: '';
        @include size(100%, 50px);
        @include position(absolute, null 0 -10px 0);
        background-image: linear-gradient(to bottom, rgba($black, 0), $black);
        opacity: 0.8;
        z-index: 10;
      }
    }

    img {
      object-fit: contain;
      max-height: 100%;
    }

    figcaption {
      @include position(absolute, 0 null null null);
      width: 100%;
      font-size: 14px;
      padding: 10px;
      color: $white;
      font-weight: normal;
      display: flex;
      justify-content: space-between;
      z-index: 20;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    z-index: 20;
  }

  &-time {
    font-size: 12px;
    opacity: 0.8;
    color: $white;
  }
}
