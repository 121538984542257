.frame {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #343a40;
  user-select: none;
  overflow: hidden;
  font-size: 13px;
  height: 138px;
  width: 247px;
  color: $white;
  margin: 0;
  border-radius: 8px;
  &--widget {
    align-items: flex-start;
    height: 302px;
  }
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    background-color: $metallic-blue;
    transition: opacity 0.3s;
  }
  &:hover {
    &:before {
      opacity: 0.8;
    }
  }

  img {
    display: block;
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }

  figcaption {
    display: flex;
    flex-direction: column;
    @include position(absolute, 0 0 0 0);
    justify-content: space-between;
    font-weight: normal;
    font-size: 14px;
    color: $white;
    z-index: 30;
  }
}
.frame__header,
.frame__bottom {
  display: flex;
  align-items: center;
  padding: 5px;
}
.frame__bottom {
  justify-content: space-between;
  padding: 5px 10px 5px 5px;
}
.frame__header {
  justify-content: flex-end;
}
.frame__title {
  padding: 5px 6px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    line-height: 12px;
  }
}
.frame__activity {
  display: flex;
  flex-direction: column;
  img {
    margin-right: 5px;
  }
  p {
    font-size: 12px;
    font-weight: 800;
    line-height: 12px;
  }
}

.frame__time {
  font-size: 12px;
  margin-right: 8px;
}

.frame__user {
  cursor: pointer;
  color: $red;
  a {
    color: $red;
  }
}

.frame__video {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #1e2125;
  @include size(100%);
  video {
    display: flex;
    align-items: center;
    justify-content: center;
    @include position(absolute, 0 0 0 0);
    @include size(100%);
    font-size: 12px;
    color: $white;
    z-index: 10;
  }
}
.camera-list__widget {
  background-color: #343a40;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 12px;
}
