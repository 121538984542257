.loader-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($black, 0.6);
  z-index: 99999;
}

.loader {
  position: absolute;

  div {
    background-color: $white;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    position: absolute;

    &:first-child {
      top: 25px;
      left: 0;
      animation: ball-spin-loader 1s 0.96s infinite linear reverse;
      transform: scale(0.4, 0.4);
    }
    &:nth-child(2) {
      top: 17.04545px;
      left: 17.04545px;
      animation: ball-spin-loader 1s 0.84s infinite linear reverse;
      transform: scale(0.4, 0.4);
    }
    &:nth-child(3) {
      top: 0;
      left: 25px;
      animation: ball-spin-loader 1s 0.72s infinite linear reverse;
      transform: scale(0.5, 0.5);
    }
    &:nth-child(4) {
      top: -17.04545px;
      left: 17.04545px;
      animation: ball-spin-loader 1s 0.6s infinite linear reverse;
      transform: scale(0.6, 0.6);
    }
    &:nth-child(5) {
      top: -25px;
      left: 0;
      animation: ball-spin-loader 1s 0.48s infinite linear reverse;
      transform: scale(0.7, 0.7);
    }
    &:nth-child(6) {
      top: -17.04545px;
      left: -17.04545px;
      animation: ball-spin-loader 1s 0.36s infinite linear reverse;
      transform: scale(0.8, 0.8);
    }
    &:nth-child(7) {
      top: 0;
      left: -25px;
      animation: ball-spin-loader 1s 0.24s infinite linear reverse;
      transform: scale(0.9, 0.9);
    }
    &:nth-child(8) {
      top: 17.04545px;
      left: -17.04545px;
      animation: ball-spin-loader 1s 0.12s infinite linear reverse;
      transform: scale(1, 1);
    }
  }
}

.image-loading {
  display: flex;
  align-items: center;
  @include size(100%);
  @include position(absolute, 0 0 0 0);
  justify-content: center;
  text-align: center;
  color: #fff;
  z-index: 10;
}

/* Safari */
@-webkit-keyframes ball-spin-loader {
  0% {
    transform: scale(0.4, 0.4);
    opacity: 0.4;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes ball-spin-loader {
  0% {
    transform: scale(0.4, 0.4);
    opacity: 0.4;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 30px;
  color: $white;
  span {
    padding-left: 5px;
  }
}
