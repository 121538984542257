.events {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 0;

  .select {
    z-index: 100;
    @media (min-width: $sm) {
      width: 370px;
    }
    margin: 40px auto;
  }

  .latest-events {
    &-list {
      display: flex;
      align-items: center;
      flex-direction: column;
      list-style: none;
      padding-left: 0;
      margin: 0;
      li {
        @media (min-width: $sm) {
          width: 100%;
        }
        max-width: 480px;
        + li {
          margin-top: 20px;
        }
      }
      .frame {
        @media (min-width: $sm) {
          min-height: 270px;
        }
      }

      .btn-event {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .collapse,
  .collapsing {
    overflow: hidden;
    padding: 10px;
    background-color: $black-3;
    ul {
      padding: 0;
      li {
        @media (min-width: $sm) {
          display: flex;
        }
        list-style: none;
        display: block;
        justify-content: space-between;
        text-align: center;
        span {
          @media (min-width: $sm) {
            display: inline-block;
          }
          display: block;
        }
      }
    }
    &:not(.show) {
      display: none;
    }
  }
}

.fullscreen {
  div {
    z-index: 1000;
  }
}
