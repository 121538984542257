.maintenance-history__info {
  border-radius: 6px;
  margin-bottom: 24px;
}

.maintenance-history__info-item {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.375;

  &:last-child {
    margin-bottom: 0;
  }
}

.maintenance-history__info-item__title {
  min-width: 145px;
  margin-right: 25px;
  opacity: 0.5;
}

.maintenance-history__table{
  padding: 0;
}