.head-page-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 20px;

  @media (max-width: $sm) {
    flex-wrap: wrap;
  }

  @media (max-width: $xs) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn.btn--secondary {
    min-width: 120px;

    @include mq($sm) {
      order: 1;
    }
  }
}

.search-field {
  max-width: 300px;
  width: 100%;

  @include mq($sm, max) {
    max-width: 100%;
    margin-top: 10px;
  }

  @include mq($sm) {
    order: 2;
  }
}

.pagination {
  display: flex;
  align-items: center;
  color: rgba($white, 0.4);
  user-select: none;
  &-size {
    padding: 0 15px;
  }

  .btn {
    font-size: 18px;
    color: $white;
    opacity: 1;
  }

  @include mq($sm) {
    order: 3;
  }
}
