.camera-view {
  @media (max-width: $sm) {
    flex-wrap: wrap;
  }
}

.camera-view__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 100%;
  &.dashboard__item {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
}

.camera-view__link-back {
  @include position(absolute, 0 null null 0);
  user-select: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 16px 24px;
  cursor: pointer;
  font-size: 24px;
  line-height: 1;
  font-weight: bold;
  outline: none;
  color: $white;
  z-index: 100;

  svg {
    margin-right: 10px;
  }
}

.camera-view__player {
  position: relative;
  background-color: $black;
  // padding-top: 56.25%;
  overflow: hidden;
  font-size: 12px;
  color: $white;
  height: 0;
  flex: 1;

  video {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }
  .vjs-modal-dialog .vjs-modal-dialog-content {
    padding: 0 !important;
  }
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
  }

  // @include mq(1235px) {
  //   padding-top: 0;
  //   height: 485px;
  // }
}
.camera-view__player-video {
  video {
    height: 100%;
    background-image: linear-gradient(
      180deg,
      #212529 0px,
      rgba(33, 37, 41, 0) 80px,
      rgba(33, 37, 41, 0) 100%
    );
  }
}

.camera-view__player-video,
.camera-view__player-loader,
.camera-view__player-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  @include position(absolute, 0 0 0 0);
  @include size(100%);
}

.camera-view__player-time {
  @include position(absolute, null 0 0 null);
  padding: 15px 20px;
  font-size: 16px;
  color: $white;
  z-index: 30;
}

.camera-view__player-placeholder {
  background-color: $black;
}

.camera-view__player-error {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg,
  span {
    color: $error;
  }

  svg {
    width: 36px;
    height: 26px;
    margin-bottom: 10px;
  }
}

.camera-view__player-container {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  .player {
    flex: 1;
    min-height: 0;
  }
}

.camera-view__player-analytics {
  position: absolute;
  top: 23px;
  right: 23px;
  width: 247px;
  height: 70%;
  z-index: 40;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #497a81;
  }
}

.camera-view__timeline {
  padding: 16px 20px;
  overflow: hidden;
  border-right: 2px solid $black;

  @media (max-width: $sm) {
    padding-left: 20px;
  }
}

.camera-view__timeline-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: $md) {
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  .zoom {
    display: flex;

    @media (max-width: $md) {
      width: 100%;
      order: 3;
      margin-top: 20px;
    }

    .time {
      display: flex;
      border: 1px solid #fff;
      font-size: 12px;
      height: 24px;
      border-radius: 4px;
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #fff;
        text-decoration: none;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 1.33;
        cursor: pointer;
        min-width: 34px;
        color: #fff;

        &.is-active {
          background-color: #fff;
          color: #282828;
          cursor: default;
        }

        &:first-child {
          border-left: none;
        }

        input[type='radio'] {
          display: none;
        }
      }
    }
  }
}

.camera-view__timeline-controls__control {
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: opacity 0.3s ease-out;
    padding: 3px 9px;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    color: $white;
    height: 24px;

    @media (max-width: $md) {
      padding-left: 5px;
      padding-right: 5px;
    }

    + button {
      margin-left: 10px;

      @media (max-width: $sm) {
        margin-left: 5px;
      }
    }
    &:disabled,
    &.disabled {
      pointer-events: none;
      color: #4a4a4a;
      cursor: default;
    }

    &:hover {
      opacity: 0.7;
    }

    &.live {
      border: 1px solid $white;
      text-transform: uppercase;
      background-color: transparent;
      transition: border-color 0.3s;
      user-select: none;
      padding: 3px 9px;
      font-weight: 900;
      font-size: 12px;
      &:before {
        content: '●';
        display: block;
        margin-top: -1px;
        padding-right: 4px;
        font-size: 15px;
      }

      &.is-active {
        background-color: $white;
        border-color: $white;
        color: #282828;
        &:before {
          color: $red;
        }
        &:hover {
          border-color: rgba($white, 0.1);
          background: rgba($white, 0.7);
        }
      }

      // &:read-only {
      //   pointer-events: none;
      //   cursor: pointer;
      // }

      &:hover {
        background: rgba($black, 1);
        opacity: 1;
      }
    }
  }

  @media (max-width: $md) {
    order: 2;
  }
}

.camera-view__timeline-actions {
  @media (max-width: $md) {
    order: 1;
  }
}

.camera-view__timeline-controls__speed {
  display: flex;
  font-size: 12px;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  height: 24px;
  margin-left: 24px;

  @media (max-width: $sm) {
    margin-left: 15px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    background-color: #fff;
    color: #282828;
    min-width: 28px;
    cursor: default;
  }
  button {
    background-color: transparent;
    cursor: pointer;
    line-height: 1.33;
    font-size: 12px;
    min-width: 24px;
    outline: none;
    color: #fff;
    padding: 0;
    margin: 0;
  }
}

.frames {
  display: none;
  align-items: center;
  justify-content: center;
  @include position(absolute, null null 90px null);
  @include size(150px, 100px);
  border: 2px solid #ff001d;
  background-color: #000;
  border-radius: 5px;
  overflow: hidden;
  z-index: 100;
  img {
    display: block;
    @include size(100%);
    object-fit: contain;
    z-index: 8;
  }

  &-name {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include size(100%, 22px);
    @include position(absolute, null null 0 0);
    padding: 0 7px;
    font-size: 10px;
    color: $white;
    z-index: 10;
  }

  &:after {
    content: '';
    display: block;
    @include size(16px);
    @include position(absolute, calc(50% - 8px) null null calc(50% - 8px));
    border: 3px solid $white;
    border-color: $white transparent $white transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    border-radius: 50%;
    z-index: 5;
  }

  &:before {
    content: '';
    @include size(100%, 30px);
    @include position(absolute, null null 0 0);
    background-image: linear-gradient(to bottom, rgba($black, 0), $black);
    opacity: 0.8;
    z-index: 10;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.times {
  display: none;
  position: absolute;
  @include size(50px, 20px);
  border: 2px solid #292c2f;
  background-color: #1c1e21;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-radius: 5px;
  overflow: hidden;
  padding: 1px 3px;
  font-size: 10px;
  color: $white;
}

.camera-view__chart-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.camera-view__chart-button {
  @include button;

  position: relative;
  margin-top: 7px;

  &:before {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: $white;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-60%);
  }

  & svg.camera-view__chart-button__icon {
    position: relative;
    color: $grey-4;
    width: 18px;
    height: auto;
  }

  &--disabled {
    &:before {
      opacity: 0.4;
    }
  }
}

.camera-view__chart {
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1;
  text {
    font-size: 10px;
    &.exclamatory {
      font-size: 11px;
      user-select: none;
      cursor: pointer;
    }
  }
  .axis {
    color: #7b838b;
    .domain {
      display: none;
    }

    &.date text {
      fill: #c3c4c5;
    }

    &.month {
      line {
        display: none;
      }

      text {
        fill: #fff;
      }
    }
  }

  .camera-view__timeline {
    cursor: pointer;
  }

  .eventLine,
  .recodingLine {
    pointer-events: none;
  }
}

.camera-view__chart-legend {
  display: flex;
  user-select: none;
  list-style: none;
  cursor: default;
  padding-left: 0;
  margin: 5px 0;
  li {
    display: flex;
    align-items: center;
    white-space: nowrap;
    line-height: 10px;
    color: #868e96;
    font-size: 10px;
    + li {
      margin-left: 10px;
    }
  }

  .square {
    display: inline-block;
    background-color: $black;
    @include size(8px);
    margin-right: 5px;
  }
}

.camera-view__timeline-actions {
  display: flex;
  align-items: center;

  .btn--live {
    margin-left: 20px;
  }
}

.camera-view__timeline-actions-button {
  color: $white;
  width: 34px;
  padding: 0;
  margin-right: 10px;
}
