.header {
  background: $black-2;
  border-bottom: 1px solid $black-3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 24px;
  overflow: visible;

  @media (max-width: $xs) {
    padding-left: 20px;
    padding-right: 20px;
  }

  nav {
    @media (max-width: $sm) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: -999px;
      right: auto;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: $black-2;
      z-index: 999;
    }
  }

  .header__nav-btn-grop {
    display: flex;
    align-items: center;
    z-index: 999;
    button {
      position: relative;
      .notifications-count {
        color: $white;
        position: absolute;
        width: 14px;
        height: 14px;
        border: solid 1px $black-2;
        background-color: $metallic-blue;
        top: 2px;
        border-radius: 14px;
        right: 7px;
        font-size: 9px;
        font-weight: bold;
      }
    }
  }
  &.menu-open {
    .nav {
      left: 0;
    }

    .mobile-btn {
      span {
        background-color: transparent;

        &::before {
          transform: translateY(0) rotate(45deg);
        }

        &::after {
          transform: translateY(0) rotate(-45deg);
        }
      }
    }
  }
}

.logo {
  z-index: 9999;

  &:hover {
    opacity: 1;
  }
}

.mobile-btn {
  padding: 0;
  background-color: transparent;
  margin-left: 10px;
  width: 16px;
  height: 16px;
  font-size: 0;
  z-index: 9999;

  @media (max-width: $sm) {
    display: inline-block;
  }

  &:hover {
    span,
    span:after,
    span:before {
      background-color: $white;
    }
  }

  span,
  span:after,
  span:before {
    transition: background-color 0.3s, transform 0.3s ease;
    background-color: rgba($white, 0.5);
    width: 16px;
    height: 2px;
  }

  span {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &:after,
    &:before {
      position: absolute;
      content: '';
      left: 0;
    }

    &::before {
      transform: translateY(5px);
    }

    &::after {
      transform: translateY(-5px);
    }
  }
}

.popover-alert {
  .popover {
    min-width: 400px;
    @media (max-width: $xs) {
      min-width: 365px;
    }

    &-info-link {
      cursor: pointer;
    }

    &-info-top {
      margin-bottom: -5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      color: $white;
      strong {
        margin-right: 5px;
      }
      span {
        color: $white;
      }
    }

    &-info-bottom {
      color: rgba($white, 0.5);
    }

    .popover-info-date {
      text-align: right;
      img {
        max-height: 14px;
      }
    }

    .popover-info-time {
      font-size: 12px;
      color: rgba($white, 0.5);
      margin-right: 10px;
    }
  }
}

//
.header__nav {
  display: flex;
  align-items: flex-start;
}

.header__nav-link {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 42px;
  color: $white;
  opacity: 0.5;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;

  @media (max-width: $sm) {
    margin-right: 0;
    margin-bottom: 40px;
  }

  &:hover,
  &--active {
    opacity: 1;
  }

  &--menu-item {
    white-space: nowrap;
    margin-bottom: 10px;
  }

  &:last-child {
    // margin-right: 0;
  }

  svg {
    margin-right: 8px;
    height: 16px;
    width: auto;
  }
}

.header__nav__dropdown-menu {
  .dropdown-item {
    &:hover {
      background-color: transparent;
    }

    &:last-child {
      .header__nav-link--menu-item {
        margin-bottom: 0;
      }
    }
  }
}

.header__menu-item {
  cursor: pointer;
  position: relative;
  &--dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header__menu-item__chevron-down {
    width: 8px;
    height: auto;
  }
}

.header__menu-item__title {
  margin: 0 5px 0 0;
}

.header__menu-item__menu {
  position: absolute;
  top: calc(100% + 16px);
  left: 0;
  border-radius: 6px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: $grey-3;
  padding: 20px 20px 15px;
  box-shadow: nth($shadows, 1);
  z-index: 10;
}

.header__user-avatar {
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}
