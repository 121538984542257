.latest-alerts {
  overflow-x: hidden;
  overflow-y: auto;
  flex: auto;
  scroll-padding: 0 14px 0 0;
  h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
    margin: 16px 0;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #497a81;
  }
}

.latest-alerts__list {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  list-style: none;
  max-width: 326px;
}
.latest-alerts__not-found {
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  margin: 20px 0;
}
.latest-alerts__item-wrapper {
  padding: 0 0 8px 0;
}
.latest-alerts__item {
  padding: 8px;
  background-color: $grey-3;
  border-radius: 6px;
  transition: background-color 0.3s;

  &--error {
    background-color: $red-4;
  }

  &--warning {
    background-color: $brown;
  }
}
.latest-alerts__item {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.latest-alerts__item-image {
  border-radius: 4px !important;
}
.latest-alerts__item-info {
  flex: 1;
  padding-left: 12px;
  max-width: calc(100% - 44px);
}
.latest-alerts__item-info__item-wrapper {
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-bottom: 8px;
  }
}
.latest-alerts__item-info__item {
  flex-shrink: 0;
  // max-width: 50%;

  .dropdown-dots-menu {
    margin-left: 8px;
  }
}

.latest-alerts__item-info__text {
  color: $white;
  font-size: 12px;
  line-height: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &--name {
    font-size: 14px;
    // cursor: pointer;
  }
  &--opacity {
    opacity: 0.5;
  }
  &--link {
    &:hover {
      // text-decoration: underline;
    }
  }
}
