.form-signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 100%;
  padding-top: 40px;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .logo {
    @include size(140px);
    margin-bottom: 30px;
  }

  form {
    @extend .w-100;
    max-width: 340px;
    padding: 0 20px;
  }

  .copyright {
    color: rgba($black, 0.4);
    font-size: 14px;
    line-height: 1.71;
    text-align: center;

    a {
      color: rgba($black, 0.4);
      &:hover {
        color: rgba($black, 1);
      }
    }
  }
}
