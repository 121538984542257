.dashboard {
  //
}
.dashboard__wrapper {
  display: flex;
  justify-content: flex-start;
}
.dashboard__content {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
  padding-right: 24px;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #497a81;
  }
  &::-webkit-scrollbar-corner {
    background-color: #2e3236;
  }
}
.dashboard__item {
  display: flex;
  flex-direction: column;
  &--alerts {
    min-width: 374px;
    width: 374px;
    flex-shrink: 0;
    background-color: $black-2;
  }
  &--cameras {
    flex: 1;
    padding: 18px 0 18px 24px;
  }
}
.dashboard__list-wrapper {
  overflow: hidden;
}
.dashboard__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  list-style: none;
  margin: -8px;
  padding: 0;
  li {
    padding: 8px;
  }
}
.dashboard__list--masonry {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  overflow: visible;
  margin: -8px;
  padding: 0;
}
.masonry--column {
  width: 247px !important;
  margin: 8px;
}
.dashboard__alerts-wrapper {
  display: flex;
  flex-direction: column;
  padding: 18px 0 0 24px;
}

.dashboard__filter {
  margin-bottom: 17px;
  &-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 9px;
  }
}
