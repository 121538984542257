.wrap-settings-camera-list {
  overflow: auto;
  height: 100%;
  padding-bottom: 40px;
}

.settings-camera {
  &-list {
    padding: 0 10px;
    background: $black-2;

    @media (max-width: $xs) {
      margin-bottom: 90px;
    }

    &-element {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid rgba($black, 0.3);
      position: relative;

      @media (max-width: $sm) {
        flex-wrap: wrap;
      }

      &:first-child {
        color: rgba($white, 0.4);
        border-bottom: 2px solid rgba($black, 0.3);

        @media (max-width: $sm) {
          display: none;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      svg {
        color: $white;
      }
    }

    &-name {
      flex-basis: 33%;
      padding-right: 20px;

      @media (max-width: $sm) {
        flex-basis: 100%;
        display: flex;
        padding-right: 70px;
        margin-bottom: 10px;
      }

      a {
        color: $white;
      }
    }

    &-location {
      flex-basis: 61%;
      padding-right: 20px;

      @media (max-width: $sm) {
        display: flex;
        flex-basis: 100%;
        padding-right: 0;
      }
    }

    &-btn-group {
      flex-basis: 6%;
      min-width: 66px;
      text-align: right;

      svg {
        color: rgba($white, 0.4);
      }

      button:hover {
        svg {
          color: $white;
        }
      }

      @media (max-width: $sm) {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }
}

.modal-settings {
  .modal-body {
    display: flex;
    justify-content: space-between;

    @media (max-width: $sm) {
      flex-direction: column;
    }
  }
}

.video-upload {
  flex-basis: 50%;
  .camera-preview {
    @extend .w-100;
    position: relative;
    max-width: 320px;
    border: 1px solid $black-3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 192px;

    @media (max-width: $sm) {
      max-width: 100%;
      height: auto;
    }

    @media (max-width: 380px) {
      min-height: 133px;
    }

    svg {
      width: 36px;
      height: 26px;
      margin-bottom: 10px;
    }

    svg,
    span {
      color: rgba($black, 0.3);
    }

    .connect,
    .error {
      position: absolute;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .connect {
      .loader-wrap {
        background: transparent;
        transform: scale(0.65);
        top: -17px;
        left: -2px;
      }

      span {
        display: block;
        padding-top: 50px;
        color: $white;
      }
    }

    .error {
      svg,
      span {
        color: $error;
      }
    }
  }

  video {
    width: 100%;
  }
}
