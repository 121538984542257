.table__container {
  width: 100%;
  height: 100%;
  padding: 18px 24px;

  &--disabled {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $black;
      opacity: 0.6;
      z-index: 2;
    }
  }

  &--no--padding {
    padding: 0;
  }
}

.table__title-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.table__title {
  margin-bottom: 9px;
}

.table__block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.table__toolbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.table__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.table__pagination-totals {
  font-size: 14px;
  line-height: 16px;
  color: $white;
  opacity: 0.5;
  margin-right: 18px;
}

.table__pagination-button {
  color: $white;
  cursor: pointer;
  padding: 8px 16px;
  opacity: 0.5;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  &--disabled {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
  }

  &--prev {
    margin-right: 6px;
  }

  &--next {
    margin-right: -16px;
  }
}

.table__filters {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 16px;
}

.table__page-button {
  margin-right: 20px;
  border: none;
}

.table__table {
  width: 100%;
  background-color: $black-2;
  border-radius: 6px;
  th,
  td {
    font-size: 14px;
    line-height: 19px;
    padding: 10px 10px 11px;
    transition: background-color 0.3s;
    color: $white;
    vertical-align: middle;
    white-space: pre-wrap;

    &:first-child,
    &:last-child {
      width: 10px;
      padding: 0;
      border: none;

      &:after {
        display: none;
      }
    }
  }

  th {
    background-color: $black-2;
    color: rgba(255, 255, 255, 0.3);
    font-weight: normal;
    position: sticky;
    z-index: 1;
    top: 0;
    left: 0;
    &:after {
      left: 0;
      width: 100%;
      bottom: 0;
      content: '';
      position: absolute;
      border-bottom: 2px solid $black-4;
    }
  }

  td {
    border-bottom: 1px solid $black-4;
  }

  tbody {
    tr {
      &.active,
      &:hover {
        td {
          background-color: $grey-3;
        }
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.table__filters-wrapper {
  display: flex;
}

.table__filters-search {
  color: $white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 8px;
  background: $black-opacity-3;
  border-radius: 6px;
  padding: 0 10px;
  height: 30px;
}

.table__filters-search-btn {
  margin-right: 6px;
  opacity: 0.5;
}

.table__filters-search-input {
  color: $white;
  width: 136px;
  &::placeholder {
    color: $white;
    opacity: 0.5;
  }
}

.table__wrapper {
  flex: 1;
  min-height: 0;
  border-radius: 6px;
  overflow: hidden;
}

.table__wrapper__inner {
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #497a81;
  }
  &::-webkit-scrollbar-corner {
    background-color: #2e3236;
  }
}
.scrollable--table {
  max-height: 300px;
  overflow-y: scroll;
  th {
    background-color: #212529 !important;
    z-index: 10;
  }
}
.table__wrapper__form-inner {
  background-color: $black-opacity-3;
  border-radius: 6px;

  .table__table {
    background-color: transparent;
  }

  th {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5);
    &:after {
      left: 0;
      width: 100%;
      bottom: 0;
      content: '';
      position: absolute;
      border-bottom: 2px solid $black-opacity-3;
    }
  }

  td {
    border-bottom: 1px solid $black-opacity-3;
  }

  tbody {
    tr {
      &:hover {
        td {
          background-color: transparent;
        }
      }
    }
  }
}

.widget-table {
  th {
    text-align: left !important;
    border-top: none !important;
  }
  td {
    vertical-align: middle !important;
  }
}

.report-table {
  th {
    text-align: left !important;
    border-top: none !important;
  }
  td {
    vertical-align: middle !important;
  }
  tfoot {
    border-top: 1px solid white;
  }
}

.sortable-cell {
  cursor: pointer;

  &:hover {
    background-color: $grey-3;
  }
}

.table__cell {
  &--bold {
    font-weight: 800;
    white-space: nowrap;
  }

  &__list {
    display: flex;
  }

  &__long-list {
    display: flex;
    column-gap: 5px;
    row-gap: 5px;
    flex-wrap: wrap;
    max-height: 100px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #2e3236;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #2e3236;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #497a81;
    }
  }

  &__name-indicator {
    display: flex;
    align-items: center;
  }
}

.table__cell-button {
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: inherit;
}

.table-editable {
  &__numeric-input {
    display: inline-block;
    input {
      padding-left: 0px !important;
      border: none !important;
      color: white;
      width: 100%;
    }

    b {
      background: transparent !important;
      box-shadow: none !important;
      border: none !important;

      &:hover {
        i {
          transform: scale(1.2);
        }
      }

      i {
        font-size: 16px;
      }

      &:nth-child(2) {
        i {
          border-bottom-color: white !important;
        }
      }

      &:nth-child(3) {
        i {
          border-top-color: white !important;
        }
      }
    }
  }

  &__tools {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 5px;
    white-space: nowrap;
    &-btn {
      width: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #497a81;
    }
  }
}
