.btn {
  @include button;
  display: inline-block;
  background-color: transparent;
  border: 1px solid transparent;
  vertical-align: middle;
  text-align: center;
  padding: 5px 10px 4px;
  user-select: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.36;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
  border-radius: 6px;
  color: #212529;
  cursor: pointer;
  outline: none;
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--link {
    background: transparent;
    text-decoration: none;
    color: $white;
    opacity: 0.5;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &--transparent {
    background: transparent!important;
    cursor: pointer;
    outline: none;
    border: none;
  }

  &--primary {
    background-color: $black;
    color: $white;
  }

  &--secondary {
    background-color: $metallic-blue;
    color: $white;
    &:hover {
      background-color: rgba(73, 122, 129, 0.6);
    }
  }

  &--dark {
    background-color: $grey-2;
    color: $white;
  }

  &--black {
    background-color: rgba(0, 0, 0, 0.3);
    color: $white;
  }

  &--color-secondary {
    background-color: $black-2;
    color: $metallic-blue;
  }

  &--color-error {
    color: $red-5;
    background-color: $black-2;
  }

  &--larger {
    font-size: 16px;
    padding: 14px 25px 13px;
    line-height: 1.19;
  }

  &--live {
    position: relative;
    background: rgba(0, 0, 0, 0.08);
    padding: 2px 5px 1px 12px;
    backdrop-filter: blur(8px);
    border: 1px solid $white;
    border-radius: 4px;
    font-weight: 800;
    font-size: 10px;
    line-height: 1;
    cursor: pointer;
    color: $white;
    transition: all 0.3s;
    &:hover {
      background-color: $grey-4;
    }

    &:before {
      content: '';
      @include size(5px);
      position: absolute;
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
      background-color: $white;
      border-radius: 50%;
    }

    &.btn--live-active {
      background-color: $red-3;
      border-color: $red-3;
    }
  }

  &--text-primary {
    color: $white;
  }
}

.btn__icon-start {
  margin-right: 5px;
}

.btn__icon-end {
  margin-left: 5px;
}

.selected-camera__settings-info-title {
  font-size: 12px;
  line-height: 14px;
  opacity: 0.5;
  margin-bottom: 6px;
}

.selected-camera__settings-info-text {
  font-size: 14px;
  line-height: 1;
}
