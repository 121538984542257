.subject {
  @extend .w-100;
  display: flex;

  @media (max-width: $sm) {
    flex-direction: column;
  }
}

.wrap-subject-list {
  overflow: auto;
  height: 100%;
  padding-bottom: 40px;
}

.subject {
  overflow-x: hidden;
  overflow-y: auto;
  &-list {
    background: $black-2;
    @media (max-width: $xs) {
      margin-bottom: 90px;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($black, 0.3);

    @media (max-width: $sm) {
      position: relative;
      padding: 10px 20px 10px 86px;
      min-height: 77px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    a {
      color: $white;
    }

    &:last-child {
      border-bottom: none;

      @media (max-width: $xs) {
        border-bottom: 1px solid rgba($black, 0.3);
      }
    }

    &-name {
      display: flex;
      align-items: center;
      font-size: 16px;
      flex-basis: 30%;

      @media (max-width: $sm) {
        font-size: 14px;
      }
    }

    &-ava {
      display: block;
      @include size(100%, 50px);
      margin-right: 20px;
      overflow: hidden;
      max-width: 50px;

      @media (max-width: $sm) {
        @include position(absolute, 0 null null 0);
        max-width: 76px;
        margin-right: 0;
        height: 76px;
      }

      img {
        @include size(100%);
        object-fit: cover;
      }
    }

    &-info {
      flex-basis: 30%;

      span {
        margin-right: 15px;

        @media (max-width: $sm) {
          font-size: 12px;
          margin-right: 10px;
        }
      }
    }

    &-location {
      flex-basis: 40%;

      @media (max-width: $sm) {
        white-space: nowrap;
        width: calc(100% - 1px);
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span {
        margin-right: 15px;

        @media (max-width: $sm) {
          font-size: 12px;
          margin-right: 10px;
        }
      }
    }
  }

  &-info {
    // flex-basis: 50%;
    // padding: 20px 46px 20px 20px;
    flex: 1;

    @media (max-width: $sm) {
      flex-basis: auto;
      padding-right: 0;
      margin-right: -7px;
    }

    @media (max-width: $xs) {
      padding-right: 20px;
    }

    &-link {
      margin-bottom: 20px;
      position: relative;

      a {
        font-size: 16px;
        color: $white;
        font-weight: bold;
        text-transform: capitalize;
        display: block;

        @media (max-width: $xs) {
          padding-left: 20px;
        }
      }

      svg {
        height: 21px;
        position: absolute;
        left: -20px;
        top: -2px;

        @media (max-width: $xs) {
          left: 0;
        }
      }
    }

    &-head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      strong {
        display: block;
        width: 100%;
        font-size: 20px;
        margin-bottom: 10px;

        @media (max-width: $sm) {
          font-size: 18px;
        }
      }

      span {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }

        @media (max-width: $sm) {
          font-size: 14px;
        }
      }
    }

    &-btn-group {
      min-width: 66px;
      text-align: right;

      svg {
        color: rgba($white, 0.4);
      }

      button:hover {
        svg {
          color: $white;
        }
      }
    }
  }

  &-alerts {
    // flex-basis: 50%;
    // border-left: 1px solid $black-3;
    // @include position(relative, 0 0 0 0);

    @media (max-width: $sm) {
      // flex-basis: auto;
      // height: 100%;
      // border: 1px solid $black-3;
    }

    &-inner {
      height: 100%;
      // padding: 20px;
      // background: $black-2;
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: auto;

      @media (max-width: $sm) {
        margin-right: 0;
        height: 255px;
        padding-top: 0;
      }

      & > strong {
        display: block;
        margin-bottom: 10px;

        @media (max-width: $sm) {
          text-align: center;
        }
      }
    }

    &-sm-x-scroll {
      @include mq($sm, max) {
        overflow-y: hidden;
        overflow-x: auto;
        strong {
          display: none;
        }

        .infinity-scroll-loading {
          @include position(absolute, 0 0 null 0);
          text-align: right;
          padding: 14px 20px;
          z-index: 100;
        }

        .latest-alerts-list,
        .subject-alerts-list {
          flex-direction: row;
          display: flex;
          li {
            box-sizing: content-box;
            padding-right: 20px;
            flex: 0 0 100%;
            + li {
              margin-top: 0;
            }
          }
        }
      }
    }

    &-list {
      margin: 0 0 0 -10px;

      @include mq($sm, max) {
        margin: 0;
        padding-top: 50px;
        flex-direction: row;
        align-items: center;
      }

      li {
        width: 100%;
        max-width: 330px;
        margin: 10px;

        @media (max-width: $xs) {
          margin: 0;
        }

        a {
          width: 100%;
        }

        .frame {
          @include mq($sm) {
            height: auto;
          }
        }
      }
    }

    .sticky {
      @include position(absolute, 0 0 null 0);
      padding: 16px;
      text-align: center;
      z-index: 10;
      @include mq($sm) {
        display: none;
      }
    }
  }
}

.modal-subjects {
  .form-fields {
    display: flex;

    @media (max-width: $sm) {
      flex-wrap: wrap;
    }

    & > div {
      position: relative;
      &:first-child {
        margin-right: 20px;

        @media (max-width: $sm) {
          margin-right: 0;
        }
      }
    }

    .half-block,
    .half-field {
      width: 50%;

      @media (max-width: $xs) {
        width: 100%;
      }
    }

    .half-block {
      & > div {
        min-height: 80px;
        position: relative;
      }

      @media (max-width: $sm) {
        width: 100%;
      }
    }

    .half-field-group {
      display: flex;

      @media (max-width: $xs) {
        flex-wrap: wrap;
      }

      & > div {
        &:first-child {
          margin-right: 10px;

          @media (max-width: $xs) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.file-upload {
  @include mq($sm, max) {
    overflow-x: auto;
  }
}
