.mr-10 {
  margin-right: 10px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-28 {
  margin-bottom: 28px;
}

.w-100 {
  width: 100%;
}

.d-ib {
  display: inline-block;
}

.va-m {
  vertical-align: middle;
}

.list-unstyle {
  padding-left: 0;
  list-style: none;
}

.sr-only {
  position: absolute;
  @include size(1px);
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-center {
  text-align: center;
}
