.filter-item {
  background-color: $black-opacity-3;
  border-radius: 6px;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  display: flex;
  button {
  padding: 6px 7px;
  display: flex;
    align-items: center;
  }
  &--active {
    background-color: $metallic-blue;
  }

  .filter-item__chevron-down {
    width: 8px;
    height: auto;
    margin-left: 5px;
  }
}
.filter-item__title {
  color: $white;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  margin: 0;
}