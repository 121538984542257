.resource-limits-page {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #2e3236;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #497a81;
  }
}