.training-markup__toolbar-inner {
  width: 228px;
  margin-right: auto;
}

.training-markup__tooolbar {
  width: 268px;
  background-color: $black-2;
  padding: 12px 0 12px 20px;
}

.training-markup__filter-wrap {
  margin-bottom: 24px;
}

.training-markup__block-title {
  font-size: 12px;
  opacity: 0.5;
  margin-bottom: 7px;
}

.training-markup__section-title {
  font-size: 12px;
  line-height: 1;
  font-weight: 800;
}

.training-markup__sections-selector-wrapper {
  > p:last-child {
    font-size: 14px;
    margin-top: 12px;
    line-height: 1;
    opacity: 0.5;
  }
}

.training-markup__sections-selector {
  margin-top: 10px;
  background: $black-opacity-3;
  padding: 8px 1px;
  height: 126px;
  border-radius: 6px;
}

.training-markup__section-button {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: rgba(255, 255, 255, 0.12);
  margin-bottom: 1px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &--selected {
    background-color: $metallic-blue;
    &:hover {
      background-color: $metallic-blue-opacity;
    }
  }
}

.training-markup__section-color {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 12px;
}

.training-markup__section-screenshot {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 12px;
  background-size: cover;
  background-position: center;
}

.training-markup__section-id {
  opacity: 0.5;
  margin-right: 11px;
  font-weight: bold;
}

.training-markup__section-name {
  font-weight: 600;
}

.training-markup__project-settings {
  margin-top: 32px;
}

.training-markup__project-block {
  margin-top: 16px;
}

.training-markup__label {
  font-size: 14px;
}

.training-markup__dnn-wrapper {
  margin-top: 16px;
}

.training-markup__input-file {
  margin-top: 13px;

  input[type='file'] {
    width: 0;
    height: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }
}

.training-markup__buttons {
  button {
    margin-right: 4px;
    margin-bottom: 5px;
  }
}

.training-markup__main-screen {
  flex: 1;
}

.training-markup__player-container-inner {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.training-markup__player-cotnrols-top {
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
}

.training-markup__player-tabs {
  &.nav {
    background-color: $black;

    .nav-item {
      .nav-link {
        min-width: 144px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:first-child {
        .nav-link {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:last-child {
        .nav-link {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

.training-markup__player-cotnrols-bottom {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $black;
  border-radius: 6px;
  padding: 6px;
}

.training-markup__player-btn {
  background-color: #292929;
  color: $white;
  padding: 4px;
  min-width: 25px;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  margin-right: 2px;
  border: none;

  &:last-child{
    margin-right: 0;
    font-weight: bold;
  }

  &:hover {
    background-color: #3d3d3d;
  }
}