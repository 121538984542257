.form-group-radio {
  input[type='radio'] + label {
    position: relative;
    padding: 1px 0 1px 22px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 16px;
    color: $white;
    &:before {
      content: '';
      @include size(16px);
      @include position(absolute, 0 null null 0);
      border: solid 0.5px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0px 1.5px 1px rgba(0, 0, 0, 0.15), inset 0px 0px 2px rgba(0, 0, 0, 0.1);
      background-color: $white;
      border-radius: 50%;
    }
    &:after {
      content: '';
      display: none;
      @include size(6px);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
      box-shadow: 0 0.5px 1px 0 #3a3a3a;
      background-color: $white;
      border-radius: 50%;
    }
  }

  input[type='radio']:checked + label {
    &:before {
      background-color: $metallic-blue;
      border-color: #3e666c;
      box-shadow: none;
    }

    &:after {
      display: block;
    }
  }
}