@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,800,900');

* {
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
}

html,
body,
#root {
  background-color: $black-4;
  font-size: 16px;
  height: 100%;
  color: #fff;
}

a {
  transition: color 0.3s, background-color 0.3s, opacity 0.3s;
  text-decoration: none;
  color: $white;

  &:hover {
    text-decoration: none;
    opacity: 0.6;
  }
}

img {
  max-width: 100%;
}

button {
  touch-action: manipulation;
  outline: none;
  border: none;
}

.fade {
  transition: opacity 0.15s linear;
}

.container {
  width: 100%;
  overflow: hidden;

  @media (max-width: $xs) {
    // padding-left: 0;
    // padding-right: 0;
  }
}

.not-found {
  color: rgba(255, 255, 255, 0.5);
  padding: 40px 0;
  font-size: 28px;
}

.list {
  font-size: 14px;
  margin: 0 0 40px;
  @extend .list-unstyle;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-title {
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  color: $white;
}
.page-content {
  flex: 1;
  min-height: 0;
}

.page-content-inner {
  padding: 18px 24px;
  margin: 0;

  .page-content__header-icon {
    width: 20px;
    height: auto;
  }
}

.page-content__header {
  margin-bottom: 22px;
}

.page-content__content{
  flex: 1;
  min-height: 0;
}
