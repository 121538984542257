.selected-camera__wrapper {
  height: 100%;
  border-radius: 0;
  padding: 0;
}

.selected-camera {
  padding: 18px 24px;
}

.selected-camera__no-selected {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > svg {
    font-size: 40px;
    margin-bottom: 25px;
  }
  > p {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }

  & .selected-camera__no-connected {
    font-weight: 800;
    font-size: 20px;
  }
}

.selected-camera__header,
.selected-camera__header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-camera__title {
  font-size: 24px;
  font-weight: 600;
}

.selected-camera__header-menu {
  .btn {
    margin-left: 10px;
  }

  > .btn {
    &:last-child {
      margin-right: -10px;
    }
  }
}

.selected-camera__screen {
  width: 100%;
  padding-bottom: 56%;
  position: relative;
  margin-top: 20px;
}

.selected-camera__screen-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
  overflow: hidden;

  &--bordered {
    border: 1px solid $black-opacity-3;
  }
}

.selected-camera__setting-tabs {
  & .nav-item {
    width: 25%;
  }
}

.selected-camera__settings-info-item {
  margin-bottom: 20px;
}

.selected-camera__settings-info-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    // height: 10px;
    width: auto;
    margin-right: 10px;
  }
}
