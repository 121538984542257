.drawing-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &_full-screen {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    buttom: 0;
    margin: 50px;
    //margin-top: 25%;
    padding: 30px;
    background-color: #2e3236;
    z-index: 10;
  }

  &__tools {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    label {
      margin-right: 10px;
    }

    input {
      margin-top: 0;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      padding: 5px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      color: white;
    }
  }

  &__canvas {
    position: absolute;
  }
}