.infinity-scroll {
  &-wrapper {
    -webkit-overflow-scrolling: touch;
  }

  &-loading {
    text-align: center;
    padding: 25px 0;
  }
}
